import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { StaticImage } from 'gatsby-plugin-image';

import {
    container,
    innerContainer,
    info,
    prizes,
    prizesMobile,
} from './winter-contest-prize.module.scss';

const prizesImg = '../../assets/images/winter-contest/prizes.png';
const prizesMobileImg = '../../assets/images/winter-contest/prizes-mobile.png';

interface IWinterContestPrizeProps {
    className?: string;
}

const WinterContestPrize: React.FC<IWinterContestPrizeProps> = ({ className = '' }) => {
    const { t } = useI18next();

    return (
        <div className={`${className} ${container}`}>
            <div className={innerContainer}>
                <StaticImage src={prizesImg} alt={''} className={prizes} />
                <StaticImage src={prizesMobileImg} alt={''} className={prizesMobile} />
                <p className={info}>
                    {t('winter.contest.prize.info.1')}
                    <strong>{t('winter.contest.prize.info.2')}</strong>
                    {t('winter.contest.prize.info.3')}
                    <strong>{t('winter.contest.prize.info.4')}</strong>
                    {t('winter.contest.prize.info.5')}
                    <br />
                    <strong>{t('winter.contest.prize.info.6')}</strong>
                </p>
            </div>
        </div>
    );
};

export default WinterContestPrize;
