import React from 'react';
import { graphql } from 'gatsby';

import { container, prize } from './thank-you.module.scss';

import { IPage } from '../../../models/page.model';
import { useI18next } from '../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import WinterLayout from '../../../layouts/winter-layout';
import WinterContestPrize from '../../../components/organisms/winter-contest-prize';

interface IWinterContestThankYouPage {
    readonly data: {
        page: IPage | null;
    };
}

const ThankYou: React.FC<IWinterContestThankYouPage> = ({ data }) => {
    const { t } = useI18next();
    const { page } = data;
    const from =
        typeof window !== 'undefined'
            ? new URLSearchParams(window.location.search).get('from')
            : '';

    return (
        <WinterLayout
            page={page}
            pageType={'thank-you'}
            className={container}
            headerProps={
                from === 'winner'
                    ? {
                          titleSecondLine: t('winter.contest.thank.title.winner'),
                          subtitle: t('winter.contest.thank.subtitle.winner'),
                      }
                    : {
                          titleFirstLine: t('winter.contest.thank.title.1'),
                          titleSecondLine: t('winter.contest.thank.title.2'),
                          subtitle: t('winter.contest.thank.subtitle'),
                          smallSubtitle: t('winter.contest.thank.small.subtitle'),
                          buttonLabel: t('winter.contest.thank.button'),
                          buttonUrl: '/grzance',
                      }
            }
        >
            <WinterContestPrize className={prize} />
        </WinterLayout>
    );
};

export const query = graphql`
    query ($language: String!, $site: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }
    }
`;

export default ThankYou;
